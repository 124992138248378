import { DayPrice } from "../models/Product.model"

export const getFormattedPrice = (price: number, fractionDigits = true, currency: string = 'PLN') => {
    const value = (price / 100.00)
    var lang = 'pl-PL'
    if (currency === 'EUR') {
        lang = 'de-DE'
    }
    return new Intl.NumberFormat(lang, { 
        style: 'currency', 
        currency: currency, 
        minimumFractionDigits: (fractionDigits || value % 1 !== 0 ) ? 2 : 0,
        maximumFractionDigits: (fractionDigits || value % 1 !== 0 ) ? 2 : 0,
    }).format(
        value,
      )
}

export const getFormattedWeekPrice = (priceList: Array<{dayOfWeek: string, price: number}>, fractionDigits = true, currency: string = 'PLN') => {
      const dayOrder = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
      
      const groupByPrice = (arr: any) => {
        //@ts-ignore
        const grouped = arr.reduce((acc, { dayOfWeek, price }) => {
            const formattedPrice = getFormattedPrice(price, fractionDigits, currency)
          if (!acc[formattedPrice]) {
            acc[formattedPrice] = [];
          }
          acc[formattedPrice].push(dayOfWeek);
          return acc;
        }, {});
      
        const mergeConsecutiveDays = (days: any) => {
            //@ts-ignore
          days.sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b));
          let result = [];
          let start = days[0];
          let prev = start;
      
          for (let i = 1; i < days.length; i++) {
            if (dayOrder.indexOf(days[i]) !== dayOrder.indexOf(prev) + 1) {
              result.push(start === prev ? start : `${start}-${prev}`);
              start = days[i];
            }
            prev = days[i];
          }
          result.push(start === prev ? start : `${start}-${prev}`);
          return result;
        };
      
        for (let price in grouped) {
          grouped[price] = mergeConsecutiveDays(grouped[price]);
        }
      
        return grouped;
      };
      
      const groupedData = groupByPrice(priceList);            
      console.log( Object.entries(groupedData))
    return  Object.entries(groupedData)
  };
